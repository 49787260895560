<template>
    <div class="working-experience-card">
      <div class="working-experience-image-container">
        <img class="education-image" :src="image" alt="" height="200" width="200"/>
      </div>

      <div class="education-details">
        <h3 class="education-title">{{ title }}</h3>
        <a class="education-school" :href="schoolLink" target="_blank">{{ school }}</a>
        <div class="education-duration">
          <span class="badge">{{ duration }}</span>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'EducationCardItem',
  props: [ "title", "school", "schoolLink", "duration", "image"]
}
</script>

<style scoped>

  .working-experience-card {
    background: #fff;
    box-shadow: 0 0.1875rem 1.5rem rgba(0, 0, 0, 0.2);
    border-radius: 0.375rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
  }

  .working-experience-image-container {
    text-align: center;
  }

  .education-image {
    border-radius: 50%;
    margin: 25px;
  }

  .education-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .education-school {
    text-decoration: underline;
    margin-left: 5%;
    margin-right: 5%;
  }

  .education-school:link,
  .education-school:visited {
    color: black;
  }

  .badge {
    background-color: #2c3e50;
    color: white;
    border-radius: 10px;
    padding: 2px 10px;
    text-align: center;
  }

  .education-duration {
    margin-top: 10px;
    margin-bottom: 25px;
  }

  .education-title {
    height: 2em;
    margin-bottom: 20px;
    margin-left: 5%;
    margin-right: 5%;
  }


</style>
