import axios from 'axios';

export class cvRepository {
    async getAll() {

        const url = process.env.NODE_ENV == 'development'
            ? 'CV.json'
            : 'https://bitbucket.org/ibosca/cv-data/raw/master/CV.json';
        return await axios.get( url, { baseURL: window.location.origin });
    }
}
