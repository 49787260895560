<template>
  <section v-if="personalInformation" id="personalInformation">
    <div id="picture-container">
      <img
          id="picture"
          :src="personalInformation.image"
          :alt="`${personalInformation.name} ${personalInformation.surname}`"
          width="300"
          height="300"
      >
    </div>
    <div id="title">
      <h1>I'm {{personalInformation.name}} {{personalInformation.surname}}</h1>
    </div>
    <div id="biography">{{personalInformation.biography}}</div>
  </section>
</template>

<script>

export default {
  name: 'Header',
  props: [ 'personalInformation' ]
}
</script>

<style scoped>
#personalInformation {
  display: flex;
  flex-direction: column;
  text-align: center;
  background: linear-gradient(130deg, #90ca5a, #d7d65a 60%, #ecd770 70%);
  padding-top: 5%;
  padding-bottom: 7%;
}
#picture {
  border-radius: 50%;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border-style: solid;
  border-color: #2c3e50;
  border-width: 8px;
}

#title {
  font-family: 'Lato', sans-serif;
  font-size: 2em;
}

#biography {
  font-family: 'Lato', sans-serif;
  font-size: 1.3em;
  margin-left: 8%;
  margin-right: 8%;
  text-align: justify;
}
</style>
