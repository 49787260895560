<template>

  <Header :personal-information="personalInformation"/>
  <Education :education-list="education"/>
  <WorkingExperience :working-experience-list="workingExperience"/>
  <PersonalProjects :personal-project-list="personalProjects"/>
  <Languages :language-list="languages"/>
  <Footer :personal-information="personalInformation"></Footer>

</template>

<script>

import Header from "@/components/Header";
import Education from "@/components/Education";
import WorkingExperience from "@/components/WorkingExperience";
import PersonalProjects from "@/components/PersonalProjects";
import Languages from "@/components/Languages";
import Footer from "@/components/Footer";
import {cvRepository} from "@/repositories/cv.repository.ts";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

library.add(faStar);
library.add(faPhone);
library.add(faEnvelope);
library.add(faTimes);
library.add(faPlusCircle);
library.add(faArrowCircleUp);
library.add(faDownload);
library.add(faLinkedin);

export default {
  name: 'App',
  components: {
    Header,
    Education,
    WorkingExperience,
    PersonalProjects,
    Languages,
    Footer
  },
  data() {
    return {
      personalInformation: null,
      education: null,
      workingExperience: null,
      personalProjects: null,
      languages: null,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch () {

      const cvData = (await new cvRepository().getAll()).data;
      this.personalInformation = cvData.personalInformation;
      this.education = cvData.education;
      this.workingExperience = cvData.workingExperience
      this.personalProjects = cvData.personalProjects;
      this.languages = cvData.languages;
    }
  }
}
</script>
