<template>
  <section v-if="languageList" id="languages">
    <div id="section-title">
      <h1>Languages & CV downloads</h1>
    </div>

    <div id="language-list-container">
      <LanguageCardItem
          v-for="language in languageList"
          v-bind:key="language.name"
          :name="language.name"
          :flag="language.flag"
          :rating="language.rating"
          :document="language.document"
      >
      </LanguageCardItem>
    </div>
  </section>
</template>

<script>
import LanguageCardItem from "@/components/LanguageCardItem";

export default {
  name: 'Languages',
  components: { LanguageCardItem },
  props: [ 'languageList' ]
}
</script>

<style scoped>

#languages {
  margin-bottom: max(5%, 50px);
}

#section-title {
  font-family: 'Lato', sans-serif;
  font-size: 1.5em !important;
}

#language-list-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax( min( max(18rem, 350px), 90%), 1fr));
  grid-gap: 1rem;
}

</style>
