<template>
  <section v-if="personalProjectList" id="personal-projects">
    <div id="section-title">
      <h1>Personal Projects</h1>
    </div>
    <div id="personal-projects-container">
      <PersonalProjectCardItem
          v-for="personalProject in personalProjectList"
          v-bind:key="personalProject.name"
          :name="personalProject.name"
          :description="personalProject.description"
          :project-link="personalProject.projectLink"
          :image="personalProject.image"
          :stack="personalProject.stack"
      >
      </PersonalProjectCardItem>
    </div>

  </section>
</template>

<script>
import PersonalProjectCardItem from "@/components/PersonalProjectCardItem";

export default {
  name: 'PersonalProjects',
  components: {PersonalProjectCardItem},
  props: [ 'personalProjectList' ]
}
</script>

<style scoped>

#section-title {
  font-family: 'Lato', sans-serif;
  font-size: 1.5em !important;
}

#personal-projects-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax( min( max(18rem, 350px), 90%), 1fr));
  grid-gap: 1rem;
}

</style>
