<template>
  <transition name="fade">
    <div class="modal" v-if="show">
      <div class="modal__backdrop" @click="closeModal()"/>

      <div class="modal__dialog">
        <div class="modal__header">
          <div class="modal__header__content">
            <img id="company-logo" :src="image" alt="" height="100" width="100"/>
            <div id="position-main-information">
              <div id="role">{{role}}</div>
              <div id="company-name">
                <span class="company-name-at">at <a class="company-link" :href="companyLink" target="_blank">{{company}}</a></span>
                <span class="badge">{{ duration }}</span>
              </div>
            </div>

          </div>
          <font-awesome-icon icon="times" class="modal__close" @click="closeModal()"/>
        </div>

        <div class="modal__body">
          <div id="description">
            <div class="modal__sub-title">The challenge:</div>
            <div>{{description}}</div>
          </div>

          <div id="tech-stack">
            <div class="modal__sub-title">Tech Stack:</div>
            <div id="tech-stack-list">
              <div class="tech-stack-item" v-for="stackItem in stack" v-bind:key="stackItem">{{stackItem}}</div>
            </div>
          </div>

        </div>

        <div class="modal__footer">
          <slot name="footer"/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: "WorkingExperienceModal",
  components: { FontAwesomeIcon },
  data() {
    return {
      show: false,
      company: null,
      companyLink: null,
      duration: null,
      role: null,
      description: null,
      stack: null,
      image: null
    };
  },
  methods: {
    closeModal() {
      this.show = false;
      document.querySelector("body").classList.remove("overflow-hidden");
    },
    openModal(company, companyLink, duration, role, description, stack, image) {
      this.show = true;
      this.company = company;
      this.companyLink = companyLink;
      this.duration = duration;
      this.role = role;
      this.description = description;
      this.stack = stack;
      this.image = image;
      document.querySelector("body").classList.add("overflow-hidden");
    }
  },
};
</script>


<style scoped>
.modal {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
}
.modal__backdrop {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.modal__dialog {
  background-color: #fff;
  position: relative;
  width: 600px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  z-index: 2;
}
@media screen and (max-width: 992px) {
  .modal__dialog {
    width: 90%;
  }
}
.modal__close {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.modal__header {
  padding: 20px 20px 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.modal__body {
  padding: 10px 20px 10px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.modal__footer {
  padding: 10px 20px 20px;
}

.modal__header__content {
  display: flex;
  justify-content: flex-start;
}
#company-logo {
  border-radius: 50%;
}

#role {
  font-size: 30px;
  font-weight: bold;
  margin-left: 5px;
}

#company-name {
  font-size: 22px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

#position-main-information {
  margin-top: 5px;
  display:flex;
  flex-direction: column;
}

.modal__sub-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.badge {
  background-color: #2c3e50;
  color: white;
  border-radius: 10px;
  padding: 2px 10px;
  text-align: center;
  font-size: 16px;
}

#tech-stack-list {
  display: flex;
  flex-wrap: wrap;
}

.tech-stack-item {
  background-color: #2c3e50;
  color: white;
  border-radius: 10px;
  padding: 2px 10px;
  text-align: center;
  margin: 3px;
}

#description {
  margin-bottom: 30px;
}

.company-link {
  text-decoration: underline;
}

.company-link:link,
.company-link:visited {
  color: #2c3e50;
}

.company-name-at {
  margin-right: 10px;
  margin-bottom: 3px;
}

</style>
