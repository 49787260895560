<template>
  <section v-if="workingExperienceList" id="working-experience">
    <div id="section-title">
      <h1>Working Experience</h1>
    </div>
    <div id="working-experience-container">
      <WorkingExperienceCardItem
          v-for="workingExperienceItem in workingExperienceList"
          v-bind:key="workingExperienceItem.description"
          :company="workingExperienceItem.company"
          :company-link="workingExperienceItem.companyLink"
          :duration="workingExperienceItem.duration"
          :role="workingExperienceItem.role"
          :description="workingExperienceItem.description"
          :stack="workingExperienceItem.stack"
          :image="workingExperienceItem.image"
          @click="openModal(workingExperienceItem)"
      ></WorkingExperienceCardItem>
    </div>
    <WorkingExperienceModal ref="workingExperienceModal"></WorkingExperienceModal>
  </section>
</template>

<script>
import WorkingExperienceCardItem from "@/components/WorkingExperienceCardItem";
import WorkingExperienceModal from "@/components/WorkingExperienceModal";
export default {
  name: 'WorkingExperience',
  components: {WorkingExperienceCardItem, WorkingExperienceModal},
  props: [ 'workingExperienceList' ],
  methods: {
    openModal(workingExperience) {
      this.$refs['workingExperienceModal'].openModal(
          workingExperience.company,
          workingExperience.companyLink,
          workingExperience.duration,
          workingExperience.role,
          workingExperience.description,
          workingExperience.stack,
          workingExperience.image
      );
    }
  }
}
</script>

<style scoped>
  #section-title {
    font-family: 'Lato', sans-serif;
    font-size: 1.5em !important;
  }

  #working-experience-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax( min( max(18rem, 350px), 90%), 1fr));
    grid-gap: 1rem;
  }

</style>
