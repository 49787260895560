<template>
  <section v-if="personalInformation" id="footer">
    <div id="section-title">
      <h1>Contact information</h1>
    </div>

    <div id="footer-container">
      <a id="phone" class="contact-info" :href="`tel:${personalInformation.contact.phone}`">
        <div class="contact-info-text">
          <font-awesome-icon icon="phone" class="contact-info-icon"/>
          <div >{{personalInformation.contact.phone}}</div>
        </div>

      </a>

      <a id="email" class="contact-info" :href="`mailto:${personalInformation.contact.email}`">
        <div class="contact-info-text">
          <font-awesome-icon icon="envelope" class="contact-info-icon"/>
          <div >{{personalInformation.contact.email}}</div>
        </div>
      </a>

      <a id="linkedin" class="contact-info" :href="personalInformation.contact.linkedin" target="_blank">
        <div class="contact-info-text">
          <font-awesome-icon :icon="['fab', 'linkedin']" class="contact-info-icon"/>
          <div>{{personalInformation.contact.linkedin}}</div>
        </div>
      </a>
    </div>

  </section>
</template>

<script>

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: 'Footer',
  components: { FontAwesomeIcon },
  props: [ 'personalInformation' ]
}
</script>

<style scoped>

#footer {
  background: linear-gradient(130deg, #90ca5a, #d7d65a 60%, #ecd770 70%);  padding-top: 3%;
  padding-bottom: 5%;
}

#section-title {
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-size: 1.3em !important;
  margin-bottom: 65px;
}

#footer-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

@media (max-width: 1024px) {
  #footer-container {
    grid-template-columns: 1fr;
  }
}

.contact-info {
  text-align: center;
  text-decoration: none;
  color: #2c3e50;
  margin-bottom: 30px;
}

.contact-info-icon {
  margin-bottom: 5px;
  font-size: 30px
}

.contact-info-text {
  font-size: 20px;
  display: inline-block;
}

.contact-info-text::after {
  content: '';
  width: 0;
  height: 3px;
  display: block;
  background: #2c3e50;
  transition: 300ms;
}

.contact-info-text:hover::after {
  width: 100%;
}

</style>
