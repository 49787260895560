<template>
  <section v-if="educationList" id="education">
    <div id="section-title">
      <h1>Education</h1>
    </div>
    <div id="working-experience-container">
      <EducationCardItem
          v-for="educationItem in educationList"
          v-bind:key="educationItem.title"
          :title="educationItem.title"
          :school="educationItem.school"
          :schoolLink="educationItem.schoolLink"
          :image="educationItem.image"
          :duration="educationItem.duration"
      ></EducationCardItem>
    </div>
  </section>
</template>

<script>

import EducationCardItem from "@/components/EducationCardItem";

export default {
  name: 'Education',
  components: {
    EducationCardItem
  },
  props: [ 'educationList' ]
}
</script>

<style scoped>
  #section-title {
    font-family: 'Lato', sans-serif;
    font-size: 1.5em !important;
  }

  #working-experience-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax( min( max(18rem, 350px), 90%), 1fr));
    grid-gap: 1rem;
  }

</style>
