<template>
    <div class="working-experience-card">
      <div class="working-experience-image-container">
        <img class="working-experience-image" :src="image" alt="" height="200" width="200"/>
      </div>

      <div class="working-experience-details">
        <div class="working-experience-title">{{ role }}</div>
        <div class="working-experience-description">{{description}}</div>
        <div class="working-experience-company">at {{ company }}</div>
        <div class="working-experience-duration">
          <span class="badge">{{ duration }}</span>
        </div>
      </div>

      <div class="card-footer">
        <font-awesome-icon icon="plus-circle"/>
      </div>

    </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: 'WorkingExperienceCardItem',
  components: { FontAwesomeIcon },
  props: [ "company", "companyLink", "duration", "role", "description", "stack", "image"]
}
</script>

<style scoped>

  .working-experience-card {
    background: #fff;
    box-shadow: 0 0.1875rem 1.5rem rgba(0, 0, 0, 0.2);
    border-radius: 0.375rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    transition: 0.3s;
    cursor: pointer;
  }

  .working-experience-card:hover {
    box-shadow: 0 0.1875rem 1.5rem #2c3e50;
  }

  .working-experience-image-container {
    text-align: center;
  }

  .working-experience-image {
    border-radius: 50%;
    margin: 25px;
  }

  .working-experience-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .working-experience-company {
    text-decoration: underline;
    margin-top: 15px;
  }

  .working-experience-company:link,
  .working-experience-company:visited {
    color: black;
  }

  .badge {
    background-color: #2c3e50;
    color: white;
    border-radius: 10px;
    padding: 2px 10px;
    text-align: center;
  }

  .working-experience-duration {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .working-experience-title {
    height: 2em;
    margin-left: 5%;
    margin-right: 5%;
    font-weight: bold;
    font-size: 20px;
  }

  .working-experience-description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    margin-left: 12%;
    margin-right: 12%;
  }

  .card-footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 30px;
  }


</style>
