<template>
    <div class="personal-project-card" @click="goToProjectLink(projectLink)">
      <div class="personal-project-image-container">
        <img class="personal-project-image" :src="image" alt="" height="200" width="200"/>
      </div>

      <div class="personal-project-details">
        <h3 class="personal-project-title">{{ name }}</h3>
        <div class="personal-project-description">{{description}}</div>
      </div>

      <div class="card-footer">
        <font-awesome-icon icon="arrow-circle-up" :transform="{ rotate: 45 }" />
      </div>
    </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: 'PersonalProjectCardItem',
  components: { FontAwesomeIcon },
  methods: {
    goToProjectLink(projectLink) {
      window.open(projectLink, "_blank");
    }
  },
  props: [ "name", "projectLink", "description", "stack", "image"]
}
</script>

<style scoped>

  .personal-project-card {
    background: #fff;
    box-shadow: 0 0.1875rem 1.5rem rgba(0, 0, 0, 0.2);
    border-radius: 0.375rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    transition: 0.3s;
    cursor: pointer;
  }

  .personal-project-card:hover {
    box-shadow: 0 0.1875rem 1.5rem #2c3e50;
  }

  .personal-project-title {
    margin-left: 5%;
    margin-right: 5%;
  }

  .personal-project-image-container {
    text-align: center;
  }

  .personal-project-image {
    border-radius: 50%;
    margin: 25px;
  }

  .personal-project-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .personal-project-description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    margin-left: 12%;
    margin-right: 12%;
    margin-bottom: 5px;
  }

  .card-footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 30px;
  }

</style>
