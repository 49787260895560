<template>
    <div class="language-card" @click="downloadDocument(document)">
      <div class="language-image-container">
        <img class="language-image" :src="flag" alt="" height="200" width="200"/>
      </div>
      <div class="language-details">
        <h3 class="language-title">{{ name }}</h3>
        <div class="language-description">
          <font-awesome-icon icon="star" v-for="index in rating" :key="index"/>
        </div>
      </div>

      <div class="card-footer">
        <font-awesome-icon icon="download"/>
      </div>

    </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: 'LanguageCardItem',
  components: { FontAwesomeIcon },
  props: [ "name", "flag", "rating", "document"],
  methods: {
    downloadDocument(documentLink) {
      window.open(documentLink);
    }
  }
}
</script>

<style scoped>

  .language-card {
    background: #fff;
    box-shadow: 0 0.1875rem 1.5rem rgba(0, 0, 0, 0.2);
    border-radius: 0.375rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    transition: 0.3s;
    cursor: pointer;
  }

  .language-card:hover {
    box-shadow: 0 0.1875rem 1.5rem #2c3e50;
  }

  .language-image-container {
    text-align: center;
  }

  .language-image {
    border-radius: 50%;
    margin: 25px;
  }

  .language-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .language-description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    margin-left: 12%;
    margin-right: 12%;
    margin-bottom: 15px;
  }

  .card-footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 30px;
  }
</style>
